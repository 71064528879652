<template>
  <b-card>
    <b-row v-if="!isCreateInquiry">
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="msisdn">Nomor HP:</label>
          <input
            id="msisdn"
            type="tel"
            class="form-control"
            placeholder="628**********"
            v-model="msisdn"
          />
        </div>
        <div v-if="isSearched && userData">
          <label for="search">Akun Terdaftar:</label>
          <b-card class="bg-profile">
            <div class="d-flex">
              <div class="pr-2">
                <b-avatar
                  variant="primary"
                  src="userData.profilePicture"
                ></b-avatar>
              </div>
              <div>
                <div class="font-weight-bold">{{ userData.userName }}</div>
                <div>{{ userData.userMsisdn }}</div>
              </div>
            </div>
          </b-card>
        </div>
        <div class="form-group" v-else-if="isSearched && !userData">
          <label for="fullName">Nama lengkap:</label>
          <input
            id="fullName"
            type="text"
            class="form-control"
            v-model="name"
          />
        </div>
      </b-col>
      <b-col cols="12 text-left" class="p-0 mt-1">
        <b-col cols="col-2 md-2 sm-6 ml-1">
          <b-button
            variant="primary"
            class="btn waves-effect waves-float waves-light btn-primary mb-2 align-items-center"
            v-if="!isSearched"
            @click="getUser"
            :disabled="msisdn.length == 0"
          >
            <b-spinner small v-if="isLoading" class="mr-25"></b-spinner>
            Cari
          </b-button>
          <div v-else-if="isSearched && userData">
            <b-button
              variant="primary"
              class="btn waves-effect waves-float waves-light btn-primary mb-2 align-items-center"
              @click="handleContinueCreateInquiry"
            >
              Lanjut
            </b-button>
            <b-button
              class="btn waves-effect waves-float waves-light btn-primary mb-2 align-items-center ml-1"
              @click="handleCancelCreateInquiry"
            >
              Kembali
            </b-button>
          </div>
          <div class="d-flex" v-else-if="isSearched && !userData">
            <b-button
              variant="primary"
              class="btn waves-effect waves-float waves-light btn-primary mb-2 align-items-center"
              @click="createUser"
            >
              Daftar Akun
            </b-button>
            <b-button
              class="btn waves-effect waves-float waves-light btn-primary mb-2 align-items-center ml-1"
              @click="handleCancelUser"
            >
              Kembali
            </b-button>
          </div>
        </b-col>
      </b-col>
    </b-row>
    <b-row class="justify-content-between" v-if="isCreateInquiry">
      <b-col cols="12" lg="6">
        <div class="form-group">
          <label for="location">Pilih Lokasi Pengerjaan:</label>
          <div id="location" @click="$bvModal.show('form-address')">
            <template v-if="selectedAddressDetails">
              <div
                class="text-capitalize border-bold rounded p-1 cursor-pointer"
              >
                <div class="font-weight-bolder">
                  {{ selectedAddressDetails.label }}
                </div>
                <div class="font-weight-bold">
                  {{ selectedAddressDetails.ordersName }} ·
                  {{ selectedAddressDetails.ordersMsisdn }}
                </div>
                <div>{{ selectedAddressDetails.completeAddress }}</div>
              </div>
            </template>
            <template v-else>
              <div
                class="form-control cursor-pointer"
                style="line-height: 1.75"
              >
                Pilih Alamat
              </div>
            </template>
          </div>
        </div>
        <div class="form-group">
          <label for="category">Kategori Jasa:</label>
          <v-select
            id="category"
            v-model="formPayload.category"
            label="subCategoryName"
            :options="category"
            placeholder="Pilih kategori jasa"
            :reduce="(category) => category.subCategoryId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            @search="debouncedSearch"
          />
        </div>
        <div class="form-group">
          <label for="jobTitle">Judul Pekerjaan:</label>
          <input
            id="jobTitle"
            type="text"
            class="form-control"
            v-model="formPayload.jobTitle"
          />
        </div>
        <div class="form-group">
          <label for="description">Deskripsi pekerjaan:</label>
          <textarea
            id="description"
            class="form-control"
            rows="4"
            v-model="formPayload.jobDescription"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="6">
        <div class="form-group">
          <label for="schedule">Pilih mulai jadwal pengerjaan:</label>
          <div class="d-flex mb-25">
            <b-form-radio
              v-model="selectedOption"
              name="scheduleOption"
              value="date"
              class="mr-2"
            >
              <small>Pilih Tanggal</small>
            </b-form-radio>
            <b-form-radio
              v-model="selectedOption"
              name="scheduleOption"
              value="quickest"
            >
              <small>Pengerjaan Secepatnya</small>
            </b-form-radio>
          </div>

          <div v-if="selectedOption === 'date'">
            <flat-pickr
              id="schedule"
              v-model="workDateTime"
              class="form-control"
              :config="{
                enableTime: true,
                dateFormat: 'Y-m-d H:i',
                time_24hr: true,
              }"
            />
          </div>
          <div v-else class="form-control">Pengerjaan Secepatnya</div>
        </div>
        <div class="form-group">
          <label for="budget">Maksimal anggaran Kamu (Rupiah):</label>
          <input
            id="budget"
            type="text"
            class="form-control"
            v-model="formPayload.budget"
          />
        </div>
        <div class="form-group">
          <label for="file">Berkas Penunjang:</label>
          <b-form-file id="file" type="file" v-model="formPayload.files" />
        </div>
      </b-col>
      <b-col cols="12">
        <b-button variant="primary" @click="createItem">
          Buat Pekerjaan
        </b-button>
        <b-button class="ml-1" @click="handleCancelCreateInquiry">
          Kembali
        </b-button>
      </b-col>
    </b-row>

    <b-modal
      id="form-address"
      centered
      size="lg"
      title="Daftar Alamat"
      no-close-on-backdrop
      hide-footer
    >
      <div class="scrolling-content">
        <b-form-radio-group v-model="selectedAddress" class="w-100">
          <div
            v-for="(item, index) in address"
            :key="index"
            class="d-flex align-items-center justify-content-between border p-2 mb-1 rounded cursor-pointer"
          >
            <div class="w-75 text-capitalize">
              <div class="font-weight-bolder">{{ item.label }}</div>
              <div class="font-weight-bold">
                {{ item.ordersName }} · {{ item.ordersMsisdn }}
              </div>
              <div>{{ item.completeAddress }}</div>
            </div>
            <b-form-radio :value="item.id" @click.stop.prevent></b-form-radio>
          </div>
        </b-form-radio-group>
      </div>
      <div class="d-flex justify-content-between">
        <div class="mt-2">
          <b-button variant="primary mr-1 rounded-pill" @click="selectAddress">
            Pilih
          </b-button>
          <b-button
            variant="secondary rounded-pill"
            @click="hideModal('form-address')"
          >
            Kembali
          </b-button>
        </div>
        <div class="mt-2">
          <b-button
            variant="secondary rounded-pill"
            @click="showAddAddressModal"
          >
            Tambah Alamat Baru
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="form-add-address"
      centered
      size="md"
      title="Tambah Alamat"
      no-close-on-backdrop
      hide-footer
    >
      <div class="form-group" v-if="!isCreateAddress">
        <label for="autocomplete">Cari Lokasi:</label>
        <input
          id="autocomplete"
          type="text"
          class="form-control"
          placeholder="Ketik lokasi"
        />
      </div>
      <div v-if="isCreateAddress">
        <div class="form-group">
          <label for="label">Label Alamat:</label>
          <input
            id="label"
            type="text"
            class="form-control"
            v-model="formPayloadAddress.label"
          />
        </div>
        <div class="form-group">
          <label for="completeAddress">Alamat Lengkap:</label>
          <textarea
            rows="4"
            id="completeAddress"
            type="text"
            class="form-control"
            v-model="formPayloadAddress.completeAddress"
          />
        </div>
        <div class="form-group">
          <label for="pic">Nama PIC:</label>
          <input
            id="pic"
            type="text"
            class="form-control"
            v-model="formPayloadAddress.ordersName"
          />
        </div>
        <div class="form-group">
          <label for="ordersMsisdn">Nomor HP:</label>
          <input
            id="ordersMsisdn"
            type="text"
            class="form-control"
            v-model="formPayloadAddress.ordersMsisdn"
          />
        </div>
      </div>
      <div v-if="!isCreateAddress">
        <b-button
          variant="primary"
          class="rounded-pill"
          @click="handleContinueCreateAddress"
        >
          Lanjut
        </b-button>
      </div>
      <div v-if="isCreateAddress" class="mt-1">
        <b-button variant="primary" class="rounded-pill" @click="createAddress">
          Daftar alamat
        </b-button>
        <b-button class="rounded-pill ml-1" @click="handleBackToSearchAddress">
          Kembali
        </b-button>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { successNotification, errorNotification } from "@/auth/utils";
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BFormFile,
  BButton,
  BSpinner,
  BAvatar,
  BModal,
  BFormRadioGroup,
  BFormRadio,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormInput,
    BFormFile,
    BAvatar,
    BModal,
    BFormRadioGroup,
    BFormRadio,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      selectedOption: "date",
      isLoading: false,
      metaData: {},
      userData: {},
      address: [],
      category: [],
      msisdn: "",
      name: "",
      workDateTime: "",
      formPayload: {
        address: "",
        category: "",
        jobTitle: "",
        jobDescription: "",
        workDate: "",
        workTime: "",
        budget: "",
        files: [],
      },
      formPayloadAddress: {
        completeAddress: "",
        label: "",
        latitude: "",
        longitude: "",
        ordersMsisdn: "",
        province: "",
        city: "",
        cityDistrict: "",
      },
      validations: "",
      isSearched: false,
      isCreateAddress: false,
      isCreateInquiry: false,
      selectedAddress: null,
      selectedAddressDetails: null,
    };
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.loadGoogleMaps();
  },
  watch: {
    workDateTime(newValue, oldValue) {
      if (newValue !== oldValue) {
        const [date, time] = newValue.split(" ");
        this.formPayload.workDate = date;
        this.formPayload.workTime = time;
      }
    },
  },
  methods: {
    getUser() {
      this.isLoading = true;
      this.$http
        .get(`/admin/customer-bypass/get-user-by-msisdn/${this.msisdn}`)
        .then((response) => {
          this.userData = response.data.data;
          if (this.userData) {
            this.getAddress();
            this.getCategory();
          }
          this.isLoading = false;
          this.isSearched = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAddress() {
      this.isLoading = true;
      this.$http
        .get(`/admin/customer-bypass/address/${this.userData.userId}`)
        .then((response) => {
          this.address = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCategory() {
      this.$http
        .get("/customer/category", {
          params: {
            perPage: 15,
          },
        })
        .then((response) => {
          this.category = response.data.data;
        });
    },
    searchCategory(query) {
      this.$http
        .get(`/customer/category?name=${query}`, {
          // params: {
          //   perPage: 15,
          // },
        })
        .then((response) => {
          this.category = response.data.data;
        });
    },
    debouncedSearch: _.debounce(function (query) {
      this.searchCategory(query);
    }, 300),
    handleContinueCreateInquiry() {
      this.isCreateInquiry = true;
    },
    handleContinueCreateAddress() {
      this.isCreateAddress = true;
    },
    handleCancelCreateInquiry() {
      this.isCreateInquiry = false;
      this.isSearched = false;
      this.userData = {};
    },
    handleCancelUser() {
      this.isSearched = false;
      this.name = "";
    },
    handleBackToSearchAddress() {
      this.isCreateAddress = false;
    },
    selectAddress() {
      const selected = this.address.find(
        (item) => item.id === this.selectedAddress
      );
      if (selected) {
        this.selectedAddressDetails = selected;
        this.formPayload.address = this.selectedAddress;
        this.$bvModal.hide("form-address");
      }
    },
    hideModal(modalId) {
      this.$bvModal.hide(modalId);
    },
    showAddAddressModal() {
      this.hideModal("form-address");
      this.$bvModal.show("form-add-address");
    },
    createItem() {
      // Check if workDate or workTime is empty and delete from formPayload if true
      if (this.formPayload.workDate === "") {
        delete this.formPayload.workDate;
      }
      if (this.formPayload.workTime === "") {
        delete this.formPayload.workTime;
      }

      this.isLoading = true;
      const payload = new FormData();

      for (const key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key)) {
          if (Array.isArray(this.formPayload[key])) {
            this.formPayload[key].forEach((file, index) => {
              payload.append(`${key}[${index}]`, file);
            });
          } else {
            payload.append(key, this.formPayload[key]);
          }
        }
      }

      this.$http
        .post(`/admin/customer-bypass/inquiry/${this.userData.userId}`, payload)
        .then((response) => {
          this.isLoading = false;
          successNotification(this, "Success", "Pekerjaan berhasil dibuat!");
          this.isCreateInquiry = false;
          this.isSearched = false;
          this.msisdn = "";
          this.userData = {};
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.meta) {
            this.validations = error.response.data.meta.message;
            errorNotification(this, "Error", this.validations.join(", "));
          }
        });
    },
    loadGoogleMaps() {
      const loader = new Loader({
        apiKey: "AIzaSyCAf25lO51RcZz2XyHQ43JqGqbcAfP61kQ",
        version: "weekly",
        libraries: ["places"],
      });

      loader.load().then(() => {
        this.$root.$on("bv::modal::shown", (bvEvent) => {
          if (bvEvent.target.id === "form-add-address") {
            this.initAutocomplete();
          }
        });
      });
    },
    initAutocomplete() {
      const input = document.getElementById("autocomplete");
      if (input) {
        const autocomplete = new google.maps.places.Autocomplete(input);
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (place.geometry) {
            this.formPayloadAddress.completeAddress = place.formatted_address;
            this.formPayloadAddress.latitude = place.geometry.location.lat();
            this.formPayloadAddress.longitude = place.geometry.location.lng();
            this.extractAddressComponents(place);
          } else {
            console.error("No details available for input:", place.name);
          }
        });
      } else {
        console.error("Autocomplete input not found.");
      }
    },
    extractAddressComponents(place) {
      const addressComponents = place.address_components;

      this.formPayloadAddress.province = "";
      this.formPayloadAddress.city = "";
      this.formPayloadAddress.cityDistrict = "";

      addressComponents.forEach((component) => {
        const types = component.types;
        if (types.includes("administrative_area_level_1")) {
          this.formPayloadAddress.province = component.long_name;
        } else if (types.includes("administrative_area_level_2")) {
          this.formPayloadAddress.city = component.long_name;
        } else if (types.includes("administrative_area_level_3")) {
          this.formPayloadAddress.cityDistrict = component.long_name;
        }
      });
    },
    createAddress() {
      this.isLoading = true;
      const payload = new URLSearchParams();

      for (const key in this.formPayloadAddress) {
        if (this.formPayloadAddress.hasOwnProperty(key)) {
          if (Array.isArray(this.formPayloadAddress[key])) {
            this.formPayloadAddress[key].forEach((file, index) => {
              payload.append(`${key}[${index}]`, file);
            });
          } else {
            payload.append(key, this.formPayloadAddress[key]);
          }
        }
      }
      this.$http
        .post(`/admin/customer-bypass/address/${this.userData.userId}`, payload)
        .then((response) => {
          this.isLoading = false;
          successNotification(this, "Success", "Alamat berhasil dibuat!");
          this.getAddress();
          this.$bvModal.hide("form-add-address");
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.meta) {
            this.validations = error.response.data.meta.message;
            errorNotification(this, "Error", this.validations.join(", "));
          }
        });
    },
    createUser() {
      this.isLoading = true;
      this.$http
        .post(`/admin/customer-bypass/user`, {
          msisdn: this.msisdn,
          name: this.name,
        })
        .then((response) => {
          this.isLoading = false;
          successNotification(this, "Success", "User berhasil dibuat!");
          this.userData = {};
          this.name = "";
          this.isSearched = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.meta) {
            this.validations = error.response.data.meta.message;
            errorNotification(this, "Error", this.validations.join(", "));
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.bg-profile {
  background-color: #f5f7fa;
}

.scrolling-content {
  height: calc(100vh - 11.5rem);
  max-height: 400px;
  overflow-y: auto;
}

.border-bold {
  border: 1px solid #d8d6de;
}

.pac-container {
  z-index: 1050 !important;
}
</style>
